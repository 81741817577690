footer {
  background-color: white;
  width: 100%;
  padding: 18px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.footer__link {
  color: black;
}

.icon {
  transform: scale(1.5);
  padding: 0 18px;
  transition: all 250ms ease;
  cursor: pointer;
}

.icon:hover {
  fill: rgb(63, 63, 63);
}

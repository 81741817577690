.video--container {
  position: relative;
  width: 70%;
  height: 0;
  padding-top: 70%; /* 1:1 aspect ratio */
}

.video--container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 850px) {
  .video--container {
    width: 100%;
    padding-top: 100%;
  }
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&family=Rubik:wght@300&display=swap");

.each-slide-effect > div {
  height: 90vh;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  overflow: hidden;
}

.image__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.image__info {
  z-index: 200 !important;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  width: 100%;
  /*bottom: 22px;
  right: 52px;*/
}

.image__quote,
.image__person {
  color: white;
  font-family: "Roboto";
  z-index: 205 !important;
  margin: 6px 0;
  text-align: end;
}

.image__quote {
  font-size: 46px;
  width: 50%;
  font-weight: 600;
}

.image__person {
  font-size: 26px;
  font-weight: 400;
}

.default-nav {
  background-color: transparent !important;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 0 !important;
  transition: all 300ms ease;
  margin: 0 12px;
  transform: scale(1.6);
}

.default-nav:hover {
  transform: scale(1.8);
}

.default-nav svg path {
  fill: white !important;
  transition: all 300ms ease;
}

.default-nav:hover svg path {
  fill: rgb(199, 199, 199) !important;
}

.mobile__image {
  height: 90vh;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: none;
}

.mobile-image__info {
  position: absolute;
  bottom: 24px;
  right: 24px;
  color: white;
  display: flex;
  flex-direction: column;
}

.mobile-image__quote {
  padding: 0;
  margin: 0;
  font-size: 38px;
  text-align: end;
}

.mobile-image__person {
  padding: 0;
  margin: 0;
  text-align: end;
  font-size: 24px;
  font-weight: 500;
  margin-top: 12px;
}

.slick-arrow {
  position: absolute;
  z-index: 200;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 12px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  cursor: pointer;
}

.slick-next {
  right: 0;
}

/*

individual info CSS

*/

.image__info--1 {
  top: 68px;
  right: 44vw;
}

.image__quote--1 {
  width: 640px;
}

.image__info--2 {
  top: 200px;
  right: 30vw;
  width: 950px;
}

.image__info--3 {
  top: 200px;
  right: 40vw;
}

.image__quote--3 {
  width: 570px;
}

.image__info--4 {
  bottom: 56px;
  right: 104px;
}

.image__quote--4 {
  width: 500px;
}

.image__info--5 {
  top: 100px;
  right: 36vw;
}

.image__quote--5 {
  width: 700px;
}

@media (max-width: 4654px) {
  .image__info--1 {
    right: 68vw;
    top: 10vh;
    transform: scale(1.4);
  }

  .image__info--3 {
    top: 18vh;
    right: 66vw;
    transform: scale(1.4);
  }
}

@media (max-width: 3020px) {
  .image__info--1 {
    right: 50vw;
    transform: scale(1.1);
    top: 6vh;
  }

  .image__info--3 {
    transform: scale(1.1);
    right: 48vw;
    top: 12vh;
  }

  .image__info--5 {
    top: 12vh;
  }
}

@media (max-width: 1920px) {
  .image__info--1 {
    right: 44vw;
    transform: scale(1);
  }
}

@media (max-width: 1619px) {
  .image__info--1 {
    right: 34vw;
    transform: scale(0.8);
  }

  .image__info--2 {
    transform: scale(0.8);
    right: 22vw;
  }

  .image__info--3 {
    transform: scale(0.8);
    right: 32vw;
  }

  .image__info--5 {
    transform: scale(0.8);
    right: 24vw;
  }
}

@media (max-width: 1365px) {
  .image__info--2 {
    transform: scale(0.6);
    right: 8vw;
    top: 10vh;
  }

  .image__info--3 {
    top: 16vh;
    right: 26vw;
  }

  .image__info--4 {
    right: 50px;
    bottom: 20px;
  }

  .image__info--5 {
    transform: scale(0.6);
    right: 16vw;
  }
}

@media (max-width: 1080px) {
  .image__info--1 {
    transform: scale(0.6);
    right: 24vw;
  }

  .image__info--3 {
    transform: scale(0.6);
  }

  .image__info--4 {
    transform: scale(0.8);
    right: 0;
    bottom: 0;
  }
}

@media (max-width: 894px) {
  .image__info--2 {
    right: 0vw;
  }
}

main:has(div):has(iframe) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1404px;
  margin: 0 auto;
}

main > div:has(iframe) {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

main > div > iframe {
  width: 100%;
  height: 500px;
}

.videos__list--container {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-auto-flow: row;
  justify-content: space-between;
  margin: 0 auto;
  padding-top: 40px;
  justify-content: center;
  align-items: center;
}

.video__item {
  width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 12px 12px;
  padding: 10px 12px;
  background-color: rgb(14, 14, 14);
  border: none;
  cursor: pointer;
  border-radius: 2px;
}

.video__item:first-child {
  background-color: #303030;
}

.video__item__img--wrapper {
  width: 100%;
  margin: 0;
  padding: 0;
  height: 220px;
  overflow: hidden;
  margin-top: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
}

.video__item__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video__item__img--loading {
  background-color: rgb(108, 108, 108);
}

.video__item__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  text-align: start;
}

.video__item__title,
.video__item__creator,
.video__item__date {
  padding: 0;
  margin: 0;
}

.video__item__info-top {
  width: 100%;
  margin-top: 8px;
}

.video__item__title {
  color: white;
  font-size: 32px;
  padding-top: 8px;
  font-weight: 500;
}

.video__item__title--loading {
  height: 22px;
  width: 70%;
  background-color: rgb(108, 108, 108);
  padding: 0;
  border-radius: 4px;
}

.video__item__creator--loading {
  height: 18px;
  width: 50%;
  background-color: rgb(108, 108, 108);
  padding: 0;
  border-radius: 4px;
  margin-top: 4px;
}

.video__item__date--loading {
  height: 0px;
  width: 25%;
  background-color: rgb(108, 108, 108);
  padding: 0;
  border-radius: 4px;
  margin-top: 18px;
  margin-bottom: 6px;
}

.video__item__creator {
  color: #d6d6d6;
  padding-top: 4px;
  font-weight: 500;
}

.video__item__date {
  color: #f31a1a;
  font-size: 18px;
  font-weight: 500;
  padding-top: 10px;
}

@media (max-width: 1440px) {
  .video__item {
    width: 400px;
    padding: 4px 10px;
  }
}

@media (max-width: 1260px) {
  .video__item {
    width: 360px;
  }
}

@media (max-width: 1200px) {
  .video__item {
    width: 400px;
  }
  .videos__list--container {
    grid-template-columns: auto auto;
  }
}

@media (max-width: 830px) {
  .video__item {
    width: 360px;
  }

  .video__item__title {
    font-size: 26px;
  }

  .video__item__creator {
    font-size: 18px;
  }

  .video__item__date {
    font-size: 18px;
  }
}

@media (max-width: 780px) {
  .videos__list--container {
    grid-template-columns: auto;
    width: 94%;
    padding: 0 12px;
  }

  .video__item {
    width: 100%;
    margin: 12px 0;
  }
}

@media (max-width: 730px) {
  main > div > iframe {
    height: 400px;
  }
}

@media (max-width: 480px) {
  main > div > iframe {
    height: 300px;
  }

  .video__item__title {
    font-size: 26px;
  }

  .video__item__creator {
    font-size: 16px;
  }

  .video__item__date {
    font-size: 16px;
  }
}

nav {
  height: 250px;
  width: 100%;
}

.nav--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 50px;
}

.header__logo--wrapper {
  width: 350px;
  margin: 0;
  margin-bottom: -20px;
}

.header__logo {
  width: 100%;
}

.header__links {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.header__link {
  color: rgb(194, 194, 194);
  font-size: 22px;
  padding: 4px 0;
  transition: all 300ms ease;
  text-decoration: none;
}

.header__link:hover {
  color: white;
  padding-right: 4px;
  cursor: pointer;
}

.header__burger-bar {
  color: white;
  transform: scale(3);
  cursor: pointer;
  display: none;
}

.close__links-menu--btn,
.header__burger--btn {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

.links__menu {
  z-index: 1000;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #050505;
  display: none;
  padding-bottom: 100px;
}

.close__links--icon {
  cursor: pointer;
  transform: scale(5);
  position: absolute;
  right: 24px;
  top: 24px;
}

.menu__links {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu__link {
  color: white;
  font-size: 28px;
  font-weight: 500;
  padding: 24px 0;
  cursor: pointer;
  text-decoration: none;
}

.close__links--icon > path {
  stroke: white;
}

@media (max-width: 1200px) {
  nav {
    height: 230px;
  }

  .header__logo--wrapper {
    width: 300px;
  }
}

/* Small laptops, tablets, large smart phones */
@media (max-width: 868px) {
  nav {
    height: 200px;
  }

  .nav--container {
    padding: 0 24px;
  }

  .header__logo--wrapper {
    width: 250px;
  }
}

@media (max-width: 768px) {
  /* INSERT BUGER BAR AT THIS POINT */

  nav {
    height: 180px;
  }

  .header__link {
    display: none;
  }

  .header__burger-bar {
    display: block;
  }

  .header__logo--wrapper {
    width: 200px;
  }
}

/* Small phones */
@media (max-width: 480px) {
}
